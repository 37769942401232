import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { useAppDispatch } from ".././appstore/hooks";
import { clearPersistedState } from '.././appstore/store';
import { selectAccessToken, selectUserId, resetUserState, selectExternalId } from "../slice/userSlice";
import { fetchClients } from "../slice/clientSlice";
import { Client } from "../models/client";
import "./Assistant.css";
import Chat from './Chat';
import Layout from './Logout';
import Loading from "./Loading";
import { useNavigate } from 'react-router-dom';

type FilterAssistant = {
    clientId: number,
    assistantId: number
}

const Assistant: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [assistantDescription, setAssistantDescription] = useState<string>('');
    const [initialPrompt, setInitialPrompt] = useState<string>('');
    const [clientAssistants, setClientAssistants] = useState<any>(null);
    const [assistants, setAssistants] = useState<any>(null);
    const [isRenderAsExternal, setIsRenderAsExternal] = useState<boolean>(false);
    const [isClientSelected, setClientSelected] = useState<boolean>(false);
    const [isAssistantSelected, setAssistantSelected] = useState<boolean>(false);
    const [aiasistantId, setAIAssistantId] = useState<string>('');
    const [aithreadId, setThreadId] = useState<string>('');
    const [isOneClient, setIsOneClient] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const userId = useSelector(selectUserId);
    const accessToken = useSelector(selectAccessToken);
    const externalId = useSelector(selectExternalId);
    const navigate = useNavigate();

    const {
        watch,
        register,
        setValue,
        formState: { errors }
    } = useForm<FilterAssistant>();

    const clientId = watch("clientId");
    const assistantId = watch("assistantId");

    useEffect(() => {
        initUserClientAssistant();
    }, []);

    const initUserClientAssistant = async () => {
        try {
            setIsLoading(true);
            if (externalId && externalId > 0) {
                setIsOneClient(true);
                setIsRenderAsExternal(true);
                const assistantResponse: any = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/api/assistant/${externalId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    }
                });

                setAIAssistantId(assistantResponse.data.data.assistantId);
                setInitialPrompt(assistantResponse.data.data.initialPrompt);
                const threadRequest = {
                    userId: userId,
                    clientId: assistantResponse.data.data.clientId,
                    toolGroupId: assistantResponse.data.data.toolGroupId
                };
                const response = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/api/thread`, threadRequest, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                setThreadId(response.data.data.id);
                setIsLoading(false);
                setAssistantSelected(true);
            } else {
                const clients = (await dispatch(fetchClients('')).unwrap());
                setClientAssistants(clients);
            }
        } catch (error: any) {            
            setIsLoading(false);
            await dispatch(clearPersistedState());
            await dispatch(resetUserState());
            let url = `/`;
            if (externalId && externalId > 0) {
                url += `?externalId=${externalId}`;
            }
            navigate(url, { replace: true });
        } finally {
            setIsLoading(false);
        }
    }

    const handleSelectClient = useCallback(async (event: any) => {
        if (!clientId) {
            setClientSelected(false);
            setIsLoading(false);
            return;
        }
        const selectedClient = clientAssistants?.find((client: any) => client.id.toString() === clientId.toString());
        setAssistants(selectedClient.assistants);
        setClientSelected(true);
        setAssistantSelected(false);
        setIsLoading(false);
    }, [clientAssistants, clientId, setAssistants, setAssistantSelected, setClientSelected, setIsLoading]);

    useEffect(() => {
        if (clientAssistants?.length === 1) {
            setValue("clientId", clientAssistants[0].id);
            setIsOneClient(true);
        }
        handleSelectClient('');
    }, [clientAssistants, setIsOneClient, setValue, handleSelectClient]);

    const handleReselectClient = async (event: any) => {
        setClientSelected(false);
        setAssistants(null);
        setAssistantSelected(false);
    }

    const handleSelectAssistant = async (event: any) => {
        setIsLoading(true);
        if (!assistantId) {
            setAssistantSelected(false);
            setIsLoading(false);
            return;
        }
        const selectedAssistant = assistants?.find((assistant: any) => assistant.id.toString() === assistantId);
        setAssistantDescription(selectedAssistant.description);
        setAIAssistantId(selectedAssistant.assistantId);
        setInitialPrompt(selectedAssistant.initialPrompt);

        const threadRequest = {
            userId: userId,
            clientId: clientId,
            toolGroupId: selectedAssistant.id,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/api/thread`, threadRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setThreadId(response.data.data.id);
            setIsLoading(false);
            setAssistantSelected(true);
        } catch (error: any) {
            console.log(error.message);
            setAssistantSelected(false);
            setIsLoading(false);           
            await dispatch(clearPersistedState());
            await dispatch(resetUserState());
            let url = `/`;
            if (externalId && externalId > 0) {
                url += `?externalId=${externalId}`;
            }
            navigate(url, { replace: true });
        } finally {
            setIsLoading(false);
        }

    }

    const handleReselectAssistant = async (event: any) => {
        setAssistantSelected(false);
        setAssistantDescription('');
        setAIAssistantId('');
        setInitialPrompt('');
    }

    return (
        <div style={{
            backgroundImage: 'url(https://static.vecteezy.com/system/resources/thumbnails/005/178/915/small/abstract-white-and-light-gray-wave-modern-soft-luxury-texture-with-smooth-and-clean-subtle-background-illustration-eps-10-free-vector.jpg)',
            backgroundSize: '100% 100%',
            backgroundRepeat: "repeat",
        }}>
            <div className="genie-header">
                <img src="/logo.png" alt="App Logo" />
                {isLoading && <Loading></Loading>}
                {!isRenderAsExternal &&
                    <>
                        {!isOneClient && <>
                            <select id="clientId" {...register('clientId',)} style={{ border: clientId ? '1px solid #14694a' : '1px solid red' }} disabled={isClientSelected}>
                                <option key="clientId" value=""> Select Client</option>
                                {
                                    clientAssistants?.map((clientAssistant: any) => {
                                        return <option key={clientAssistant.id} value={clientAssistant.id}>{clientAssistant.name}</option>
                                    })
                                }
                            </select>
                            <button onClick={!isClientSelected ? handleSelectClient : handleReselectClient}>
                                {!isClientSelected ? "Select" : <FaEdit />}
                            </button>
                        </>}
                        {isClientSelected && <>
                            <select id="assistantId" {...register('assistantId',)} style={{ border: assistantId ? '1px solid black' : '1px solid red' }} disabled={isAssistantSelected}>
                                <option key="assistantId" value=""> Select Assistant</option>
                                {
                                    assistants?.map((assistant: any) => {
                                        return <option key={assistant.id} value={assistant.id}>{assistant.name}</option>
                                    })
                                }
                            </select>
                            <button onClick={!isAssistantSelected ? handleSelectAssistant : handleReselectAssistant}>
                                {!isAssistantSelected ? "Select" : <FaEdit />}
                            </button>
                        </>}
                    <div className="reload-button">
                        <Layout />
                    </div>
                    </>
                }
                
            </div>
            {isLoading && <Loading></Loading>}
            {isAssistantSelected && <>
                <Chat assistantId={aiasistantId} threadId={aithreadId} initialPrompt={initialPrompt} />
            </>
            }
        </div>
    );
};

export default Assistant;