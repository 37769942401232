import React, { useEffect } from 'react';
import { selectIsAuthenticated, selectIsAuthenticating, setExternalId, selectAuthTimestamp, resetUserState } from "./slice/userSlice";
import { useSelector } from "react-redux";
import Assistant from './components/Assistant';
import Callback from './callback';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppDispatch } from "./appstore/hooks";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const App: React.FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);  
  const authTimestamp = useSelector(selectAuthTimestamp);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const url = queryString.parseUrl(location.search);
  const externalId = url.query.externalId;
  dispatch(setExternalId(externalId));
  const AUTH_TIMEOUT = 7200000; // 2 hours

  useEffect(() => {
    if (!isAuthenticated) {
      let returnUrl = process.env.REACT_APP_CLIENT_URL + '/callback';
      if (externalId) {
        returnUrl += `${location.search}`;
      }
      window.location.href = `${process.env.REACT_APP_SERVICE_URL}/login?returnurl=${returnUrl}`;
    } else {
      const checkAuthTimeout = () => {
        if (isAuthenticated) {
          const currentTime = new Date().getTime();
          if (currentTime - (authTimestamp ? authTimestamp : 0) > AUTH_TIMEOUT) {
            dispatch(resetUserState());
          }
        }
      };

      checkAuthTimeout();

      // Set an interval to check the timeout periodically
      const intervalId = setInterval(checkAuthTimeout, 30000); // Check every 30 seconds

      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated, dispatch]);

  const PrivateRoute = ({ isAuthenticated, children }: any) => {
    return isAuthenticated ? children : <Navigate to="/" replace />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<PrivateRoute isAuthenticated={isAuthenticated}><Assistant /></PrivateRoute>} />
        <Route path="/callback" element={<Callback />} />
      </Routes>
    </>
  );
};

export default App;