import axios from 'axios';
import { Client } from '../models/client';
import { RootState } from '../appstore/store';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchClients = createAsyncThunk<Client[], string>(
    'client',
    async (email: string, thunkAPI: any) => {
        let headers: any = {};
        let state: any = thunkAPI.getState();
        let accessToken = state.user?.accessToken;
        if (accessToken)
            headers["Authorization"] = `Bearer ${accessToken}`;
        try {
            let response = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/api/clientassistant`, {
                headers: headers,
            }).catch(async (err) => {
                if (err.response.status === 404) {
                    throw new Error(`${process.env.REACT_APP_SERVICE_URL} not found`);
                }else if(err.response.status === 401){
                    throw { status: 401, message: 'Unauthorized' };
                }
                throw err;
            });
            return response.data.data.clients as Client[];
        } catch (err: any) {
            if(err?.response?.status === 401){
                throw { status: 401, message: 'Unauthorized' };
            }
            throw err;
        }
    }
);

export const clientSlice = createSlice({
    name: 'client',
    initialState: {
        loading: false,
        clients: [] as Client[],
    },
    reducers: {
        setClients(state, action: PayloadAction<Client[]>) {
            state.clients = action.payload;
        },
        clearClients: (state, action) => {
            state.clients = [];
        }
    }, extraReducers: (builder) => {
        builder
            .addCase(fetchClients.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.loading = false;
                state.clients = action.payload;
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.loading = false;                
            });
    }
});

export const { setClients, clearClients } = clientSlice.actions;

export const selectClients = (state: RootState) => state.client.clients;
export const selectLoading = (state: RootState) => state.client.loading;

export default clientSlice.reducer;