import { useState, CSSProperties } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const override : CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

export default function Loading() {
    const [color,] = useState("#177B57");
    const [loading,] = useState(true);
    return (
        <div className="loading">
            <PulseLoader cssOverride={override} color={color} loading={loading} size={25} />
        </div>
    )
}