import React, { ReactNode } from "react";
import { FaSignOutAlt } from "react-icons/fa";

import { clearPersistedState } from '.././appstore/store';
import { selectUserFirstname, selectUserLastname, resetUserState, selectExternalId, setExternalId } from "../slice/userSlice";
import { useAppDispatch, useAppSelector } from "../appstore/hooks";

const Layout: React.FC = () => {
    const firstName = useAppSelector(selectUserFirstname);
    const lastName = useAppSelector(selectUserLastname);    
    const externalId = useAppSelector(selectExternalId);
    const dispatch = useAppDispatch();

    const logout = async () => {        
        await dispatch(clearPersistedState());
        await dispatch(resetUserState());  
        let url = `${process.env.REACT_APP_CLIENT_URL}`;
        if(externalId && externalId > 0){
            await dispatch(setExternalId(externalId));
            url += `?externalId=${externalId}`;
        }
        window.location.href = url;
    };

    return (
        <div className="user-info">
            <span style={{ fontWeight: "bold" }}>
                {lastName}, {firstName}
            </span>
            <span>
                <span style={{ marginLeft: "1rem", color: "#14694a", cursor: "pointer" }} className="log-out" onClick={logout}>
                    <FaSignOutAlt size="18" className="cursor-pointer"></FaSignOutAlt>
                </span>
            </span>
        </div>
    );
};

export default Layout;
