import React, { useEffect, useState, useRef } from "react";
// import { TextField, Button, Container, Grid, CircularProgress, LinearProgress } from "@mui/material";
// import Message from "./Message";
import OpenAI from "openai";
import { useSelector } from "react-redux";
import { MessageDto } from "../models/MessageDto";
import { selectAccessToken, selectUserFirstname } from "../slice/userSlice";

import styles from "../styles/Chat.module.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.css";
import { MainContainer, ChatContainer, MessageList, TypingIndicator, MessageInput, Message  } from "@chatscope/chat-ui-kit-react";

type ChatProps = {
  assistantId: string,
  threadId: string,
  initialPrompt: string,
}

const Chat: React.FC<ChatProps> = ({ assistantId, threadId, initialPrompt }) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [openai, setOpenai] = useState<any>(null);
  const accessToken = useSelector(selectAccessToken);
  const firstName = useSelector(selectUserFirstname);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const container = useRef<HTMLDivElement>(null);

  if (!initialPrompt) {
    initialPrompt = "Let's say my name is <username>. Start with greetings and introduce yourself.";
  }

  initialPrompt = initialPrompt?.replace('<username>', firstName as any);

  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendMessage(initialPrompt, true);
    }, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openai]);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    // // Create an assistant
    // const assistant = await openai.beta.assistants.create({
    //   name: "Hockey Expert",
    //   instructions: "You are a hockey expert. You specialize in helping others learn about hockey.",
    //   tools: [{ type: "code_interpreter" }],
    //   model: "gpt-4-1106-preview",
    // });

    // // Create a thread
    //const thread = await openai.beta.threads.create();

    setOpenai(openai);
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const sendMessage = async (inputText: any, initialMessage: boolean) => {
    try {
      setIsWaiting(true);
      setIsTyping(true);
      if (!initialMessage) {
        messages.push(createNewMessage(inputText, true));
        setMessages([...messages]);
      }
      setInput("");

      // Send a message to the thread
      await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: inputText,
      });

      // Run the assistant
      const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
      });

      // Create a response
      let response = await openai.beta.threads.runs.retrieve(threadId, run.id);

      // Wait for the response to be ready
      while (response.status === "in_progress" || response.status === "queued") {
        console.log("waiting...");
        // setIsWaiting(true);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await openai.beta.threads.runs.retrieve(threadId, run.id);
      }

      // Get the messages for the thread
      const messageList = await openai.beta.threads.messages.list(threadId);

      // Find the last message for the current run
      const lastMessage = messageList.data
        .filter((message: any) => message.run_id === run.id && message.role === "assistant")
        .pop();

      // Print the last message coming from the assistant
      if (lastMessage) {
        // console.log(lastMessage.content[0]["text"].value);
        setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
      }
      setIsWaiting(false);
      setIsTyping(false);
    } catch (error: any) {
      console.error(error);
      setMessages([...messages, createNewMessage(error?.message, false)]);
      setIsWaiting(false);
      setIsTyping(false);
    } finally {
      setIsWaiting(false);
      setIsTyping(false);
    }
  }

  const handleSendMessage = async () => {
    if(!input || !input?.trim() || !input.replaceAll('&nbsp;', '')?.trim()){
      return;
    }
    const inputText = input.replaceAll('&nbsp;', '')?.trim();
    await sendMessage(inputText, false);
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className={styles.container} ref={container}>
      <MainContainer className={styles.chatContainer} responsive>
        <ChatContainer>
          <MessageList
            style={{
              backgroundImage: `url(https://static.vecteezy.com/system/resources/thumbnails/005/178/915/small/abstract-white-and-light-gray-wave-modern-soft-luxury-texture-with-smooth-and-clean-subtle-background-illustration-eps-10-free-vector.jpg)`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "repeat",
            }}
            typingIndicator={
              isTyping ? (
                <TypingIndicator content={"Genie is typing"} />
              ) : (
                <></>
              )
            }
            scrollBehavior="smooth"
          >
            <div className={styles.animatedSpacer} />
            {messages.map((message, index) => {
                if (message != null) {
                  if (!message.isUser) {
                    return (
                      <Message
                        model={{
                          message: message.content,
                          sender: "Genie",
                          direction: 0,
                          position: "single",
                          type: "custom",
                        }}
                      />
                    );
                  } else {
                    return (
                      <Message
                        model={{
                          message: message.content,
                          sender: "You",
                          direction: 1,
                          position: "first",
                          type: "custom",
                        }}
                      />
                    );
                  }
                }
              })}
          </MessageList>
          <MessageInput
            className={styles.inputBar}
            style={{backgroundColor:"white"}}
            disabled={isTyping}
            sendButton={false}
            attachButton={false}
            placeholder="Type here then press enter."
            onKeyDown={handleKeyPress}
            onSend={handleSendMessage}
            onChange={setInput}
            value={input}
          />
        </ChatContainer>        
      </MainContainer>
    </div>    
  );
};

export default Chat;