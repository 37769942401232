import { RootState } from '../appstore/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query';


const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVICE_URL}`,
    prepareHeaders: (headers:any, { getState } : any) => {
      const token = (getState() as RootState).user.accessToken
  
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    },
  })

export const api = createApi({
    reducerPath : "api",
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    tagTypes: ['User'],
    endpoints: () =>({}),
});
