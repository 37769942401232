import axios from 'axios';
import { useEffect, useState } from "react";
import queryString from 'query-string';
import { clearPersistedState } from './appstore/store';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAccessToken, setUserState, resetUserState, selectExternalId } from "./slice/userSlice";
import { useAppDispatch, useAppSelector } from "./appstore/hooks";
import Loading from "./components/Loading";

export default function Callback() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const externalId = useAppSelector(selectExternalId);

    useEffect(() => {
        const fetchUser = async () => {
            const url = queryString.parseUrl(location.search);
            const token = url.query.token;

            delete url.query['token'];
            delete url.query['refresh_token'];

            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/api/user`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                });
                dispatch(setUserState(response.data.data));
                dispatch(setAccessToken(token as string));                
                setIsLoading(false);
                let url = `/`;
                if (externalId && externalId > 0) {
                    url += `?externalId=${externalId}`;
                }
                navigate(url, { replace: true });
            } catch (error: any) {
                setError(error.message);
            setIsLoading(false);
                await dispatch(clearPersistedState());
                await dispatch(resetUserState());
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [dispatch, navigate]);

    return (<>
        <>
            {isLoading && <Loading></Loading>}
            {error ? (
                <div className="container alert alert-danger mt-4" role="alert">
                    <h5 className="alert-heading">Error </h5>
                    <hr />
                    <p> {error} </p>
                </div>
            ) : ""}

        </>
    </>);
}