import axios from 'axios';
import { User, UserState } from '.././models/user';
import { RootState } from '.././appstore/store';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUser = createAsyncThunk<User, string>(
    'user',
    async (email: string, thunkAPI:any) => {
        let headers : any = {};
        let state : any = thunkAPI.getState();
        let accessToken = state.user?.accessToken;
        if(accessToken)
            headers["Authorization"] = `Bearer ${accessToken}`;
        try {
            let response = await axios.get(`$${process.env.REACT_APP_SERVICE_URL}/api/user`,  { 
                headers: headers,
            }).catch(err => {
                if (err.response.status === 404) {
                    throw new Error(`${err.config.url} not found`);
                }
                throw err;
            });
             return response.data.data as User;
        } catch (err) {
            throw err;
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        isAuthenticated: false,
        isAuthenticating: false,
        sessionExpiryTime: null,
        externalId: 0
    } as UserState,
    reducers: {
        resetUserState: (state) => {
            state.isAuthenticated = false;
            state.isAuthenticating = false; 
            state.authTimestamp = null;
            state.accessToken = '';  
            state.externalId = 0;                            
          },
        setAccessToken(state, action : PayloadAction<string>){
            state.accessToken = action.payload;
            state.isAuthenticated = true;    
            state.authTimestamp = new Date().getTime();        
        },        
        setIsAuthenticating(state, action){
            state.isAuthenticating = action.payload;
            state.authTimestamp = new Date().getTime();
        }, 
        resetIsAuthenticating(state, action){
            state.isAuthenticating = action.payload;
            state.authTimestamp = null;
        },
        setExternalId(state, action){
            state.externalId = action.payload;
        }, 
        setUserState(state, action){
            state.loading = false;
            state.user = action.payload;
        },
        signOut(state, action){
            state.accessToken = "";
            state.user = null;
            state.isAuthenticated= false;
        }
    }
});

export const { setAccessToken, setIsAuthenticating, resetIsAuthenticating, setExternalId, setUserState, resetUserState, signOut  } = userSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const selectIsAuthenticating = (state: RootState) => state.user.isAuthenticating;
export const selectAuthTimestamp = (state: RootState) => state.user.authTimestamp;
export const selectExternalId = (state: RootState) => state.user.externalId;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserId = (state: RootState) => state.user.user?.id;
export const selectUserEmail = (state: RootState) => state.user.user?.email;
export const selectUserFirstname = (state: RootState) => state.user.user?.firstName;
export const selectUserLastname = (state: RootState) => state.user.user?.lastName;
export const selectAccessToken = (state: RootState) => state.user?.accessToken;
export const selectLoading = (state: RootState) => state.user.loading;

export default userSlice.reducer;